<style lang="scss" scoped>
.subtitle {
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(119, 119, 119, 1);
}

.active {
  border-bottom: 2px solid #000;
  font-weight: bold;
}
</style>
<script setup>
import AppTopBar from "~/components/AppTopBar.vue";
import HeadTitle from "~/components/HeadTitle.vue";
import { useCurrentUser, useFirebaseAuth } from "vuefire";
import { useQueryClient } from "@tanstack/vue-query";

const queryClient = useQueryClient();
const nuxtApp = useNuxtApp();

const user = useCurrentUser();
// const route = useRoute();
// const router = useRouter();
// const currentRoute = ref(route.fullPath);
// router.afterEach((to, from) => {
//   currentRoute.value = to.path;
// });
// nextTick(() => {
//   currentRoute.value = route.fullPath;
// });
const auth = useFirebaseAuth();

function logout() {
  auth.signOut().finally(async () => {
    nuxtApp.$intercom.trackEvent("Logged out");
    await queryClient.clear();
    await navigateTo(nuxtApp.$localePath("/login"));
  });
}
</script>
<template>
  <div class="layout-wrapper">
    <notifications width="600px" position="top center">
      <template #body="props">
        <div class="pinotqr-notification" :class="props.item.type">
          <div class="icon">
            <font-awesome-icon
              v-if="props.item.type === 'success'"
              icon="check"
              class="pinotqr-notification-icon"
            />
            <font-awesome-icon
              v-else
              icon="xmark"
              class="pinotqr-notification-icon"
            />
          </div>
          <div v-html="props.item.text" />
        </div>
      </template>
    </notifications>
    <app-top-bar></app-top-bar>
    <AuthGuard>
      <div class="layout-main-container">
        <div class="layout-main">
          <head-title>{{ $t("account.title") }}</head-title>
          <div class="subtitle">
            {{ user?.email }}
          </div>
          <div class="account-tabs">
            <div
              class="title-tab"
              :class="currentRoute == '/account' ? 'active' : ''"
            >
              <NuxtLink :to="localePath('/account')">{{
                $t("account.subscription-tag")
              }}</NuxtLink>
            </div>
            <div
              class="title-tab"
              :class="currentRoute == '/invoices' ? 'active' : ''"
            >
              <NuxtLink :to="localePath('/invoices')">{{
                $t("account.invoices-tag")
              }}</NuxtLink>
            </div>
            <div class="title-tab">
              <div class="cursor-pointer" @click="logout">
                {{ $t("account.logout") }}
              </div>
            </div>
          </div>

          <router-view></router-view>
        </div>
      </div>
    </AuthGuard>
    <ClientOnly>
      <cookie></cookie>
    </ClientOnly>
  </div>
</template>
<style scope>
.account-tabs {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  gap: 24px;
  overflow-x: auto;
  margin: 30px 0;

  .title-tab {
    font-weight: 600;

    &.active {
      color: #121212;
    }
  }
}

.account-cards {
  display: flex;
  flex-flow: row wrap;
  gap: 16px;

  .card {
    width: calc(50% - 8px);

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
}
</style>
